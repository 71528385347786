<template>
    <div>
        <div :class="'box-filter ' + (show_filter ? 'active' : '')">
            <div class="filter-inner apply" @click="handleSubmit">
                <img :src="require(`@/assets/dist/2019/images/icon/btn-ok${isUserFrench ? '-fr' : ''}.svg`)" alt="">
                <p>{{$t('Apply')}}</p>
            </div>
            <div class="filter-inner reset" @click="handleReset">
                <img src="@/assets/dist/2019/images/icon/checklist-reload.svg" alt="">
                <p>{{$t('Reset')}}</p>
            </div>
            <div class="filter-inner cancel" @click="handleCancel">
                <img :src="require(`@/assets/dist/2019/images/icon/ic-close${isUserFrench ? '-fr' : ''}.svg`)" alt="">
                <p>{{$t('Cancel')}}</p>
            </div>
        </div>
        <div :class="'sub-menu-filter menu-filter advance ' + (show_filter ? 'active' : '')" style="z-index: 99;">
            <ul> 
                <li v-for="(item,key) in filter" :key="key" >
                    <a v-if="item.title">{{item.title}} </a>
                    <ul class="list-checkbox w100">
                        <li v-for="(item2,key2) in item.options" :key="key+'-'+key2" :class="item.style">
                            <div class="box-check box-check-text box-check-xs w-100">
                                <label>
                                    <input type="checkbox" :value="item2.value" :name="item2.name" v-model="data_filter[item2.name]">
                                    <span class="check-recherche"></span>
                                    <template v-if="item.color">
                                        <div class="txt-check text-gray" :style="'color:' + item.color" >
                                            {{item2.display}}
                                        </div>
                                    </template>
                                    <div v-else class="txt-check text-gray" >
                                        {{item2.display}}
                                    </div>
                                </label>
                            </div>
                        </li> 
                    </ul>
                </li>
            </ul>
        
        </div>
    </div>
</template>

<script>
import { Base64 } from 'js-base64'
import { isUserFrench } from '@/utils';
export default {
    
    name: "FilterCeesp",
    data() {
        return { 
            temp_filter : false,
            data_filter : {
                ceesp_conclusion : [],
                ceesp_appreciation : [],
                ceesp_complementairesaapporter : [],
                ceesp_budget_impact : [],
                ceesp_orphelin : [],
            },
            filter : [
                {
                    title : this.$t('advance.ceesp.DecisionduCollegedelaHAS'),
                    options : [
                        {name : "ceesp_conclusion", value:"1", display: this.$t('advance.ceesp.Conclusion.Eligible_evaluation_medico_economique') },
                        {name : "ceesp_conclusion", value:"2", display: this.$t('advance.ceesp.Conclusion.Non_eligible_evaluation_medico_economique') }],
                    style : "p-0 w-100",               
                },
                {
                    title : this.$t('advance.ceesp.Appreciation.title'),
                    options : [
                        {name : "ceesp_appreciation", value : 'Conforme sans réserve', display: this.$t('advance.ceesp.Appreciation.conforme_sans_reserve')},
                        {name : "ceesp_appreciation", value : 'Acceptable avec réserves mineures', display: this.$t('advance.ceesp.Appreciation.acceptable_avec_reserves_mineures')},
                        {name : "ceesp_appreciation", value : 'Acceptable avec réserves importantes', display: this.$t('advance.ceesp.Appreciation.acceptable_avec_reserves_importantes')},
                        {name : "ceesp_appreciation", value : 'Non conforme (réserve majeure)', display: this.$t('advance.ceesp.Appreciation.non_conforme')},
                        {name : "ceesp_appreciation", value : 'Non documenté', display: this.$t('advance.ceesp.Appreciation.non_documente')}],
                    style : "p-0 w-100",  
                },
                {
                    title : this.$t('advance.ceesp.complementairesaapporter'),
                    options : [
                        {name : "ceesp_complementairesaapporter", value : 'oui', display: this.$t('OUI') },
                        {name : "ceesp_complementairesaapporter", value : 'Non', display: this.$t('NON') }], 
                    style : "p-0 w-100",  
                },
                {
                    title : this.$t('ceesp.Budgetary impact'),
                    options : [
                        {name : "ceesp_budget_impact", value:"oui", display: this.$t('OUI')},
                        {name : "ceesp_budget_impact", value:"Non", display: this.$t('NON')}],  
                    style : "p-0 w-100",
                },
                {
                    title : "Orphelin",
                    options : [
                        {name : "ceesp_orphelin", value:"1", display: this.$t('Orphan drug')},
                        {name : "ceesp_orphelin", value:"2", display: this.$t('Previously Orphan')}],  
                    style : "p-0 w-100",
                }

            ],

        }
    }, 
    methods: {
        handleReset(){
            for (const key in this.data_filter) {
                this.data_filter[key] = []
            }
            
            this.handleSubmit(false)
        },
        handleSubmit(isFilter = true){ 
             
            let strFilter = ""
            for (const [key, value] of Object.entries(this.data_filter)) {
                if(value.length > 0){
                    strFilter += `${key}=${value.join('#')}|` 
                }                
            }
            this.$store.dispatch("advanced_result/set_data_filter", Base64.encode(strFilter) )
            console.log(strFilter) 
            this.handleCancel()

            this.$emit('onRequest') 
            this.$emit('isFilter', isFilter) 
        }, 
        handleCancel(){
            this.$store.dispatch("advanced_result/set_show_filter", false)
        }, 
    },
    computed: {
        isUserFrench,
        show_filter () {  
            return this.$store.getters['advanced_result/show_filter']
        },  
    }, 
}
</script>

<style>

</style>