<template>
    <div>
        <Header headerType="advance_result"/>  

        <div :class="'container-fluid' + ($route.query.zoom ? ' fullscreen' : '') + (isUserFrench ? ' page-french' : '')">
            <div class="row">
                <div class="main-box-fixed">                        
                    <div class="box-fixed">
                        <div class="title">
                            <div v-if="isFilter" class="count_filter">
                                {{ count_filter + "/" + count }}
                            </div>
                            <h1 v-else>
                                {{ count }}
                            </h1>
                            <h2>{{$t('Result')}}</h2>
                        </div>
                        <div class="filters-plus" @click="handleClickShowFilter()">
                            <img :src="require(`@/assets/dist/2019/images/icon/btn-filtre-off${isUserFrench ? '-fr' : ''}.svg`)" alt="" >
                            <p>{{$t('Filters')}}</p>
                        </div> 
                    </div>                    
                    <FilterCeesp @onRequest="request_data"  @isFilter="value => isFilter = value"/>
                    <div class="advance box-fix-left" ref="menuFlag" >
                        <ul>
                            <label class="flag-active">  
                                                             
                                <li class="active pointerNone" >
                                    <img :src="require('@/assets/images/flag/new/fr.svg')" width="35" height="35" style="margin-bottom: 5px;" />
                                    <p> 
                                        <span style="margin-right: 0;"></span>
                                        Ceesp
                                    </p>
                                    <p class="short_name">(FRA)</p> 
                                </li>                                
                            </label>     
                        </ul>
                    </div>
                </div>

                <div class="main-body">
                    <div class="body-fix">
                        <div class="body-custom">
                            <div class="box-btn-menu">             
                                <a href="javascript:void(0)" class="btn btn-border-orange btn-muted pointerNone">{{$t('Overview')}}</a>
                                <a href="javascript:void(0)" @click="selectTab('expert')" :class="'btn ' + (!$route.query.tab || $route.query.tab == 'expert' ? ' btn-orange' : 'btn-border-orange')" >{{$t('Expert')}}</a>
                                <a v-if="user && user.access_clinical" href="javascript:void(0)" @click="selectTab('essais_clinique')" :class="'btn ' + ($route.query.tab && $route.query.tab == 'essais_clinique' ? ' btn-orange' : 'btn-border-orange')" >{{$t('Clinical trials')}}</a>
                                <a href="javascript:void(0)" @click="selectTab('prix')" :class="'btn ' + ($route.query.tab && $route.query.tab == 'prix' ? ' btn-orange' : 'btn-border-orange')" >{{$t('Prix')}}</a>
                            </div>
                        </div>
                    </div>

                    <div v-if="(!$route.query.tab || $route.query.tab == 'expert')">   
                    <div class="body-w100" v-if="data.data && data.data.length > 0">
                        <div class="box-detail">
                            <!-- <div class="wrapper-box-hta">
                                <h1 class="fa-pull-left mr-4 mt-1">Reimbursement Status</h1>
                                <div class="clearfix"></div>
                            </div>        -->
                            <ModalConfigColumn :page="page" :type="type" :column="orderColumn" :reset_column="reset_column" />      
                            <ModalSaveKeyword :type="type" />                                           
                            <IconTopTable @onRequest="request_data" :page="page" @onSelectExport="selectExport" @onClearExport="clearExport"/>                            
                            <div class="content-table-results mt-2">
                                <table class="table table-fixed table-stripes">
                                    <ColumnColGroup :page="page" :column="orderColumn" :default_column="default_column" />
                                    <ColumnThead :page="page" :column="orderColumn" :default_column="default_column"  />  
                                    <tbody> 
                                       <template v-for="(item, key) in data.data" >
                                       <tr v-for="(sub_item, sub_key) in max_row(item)" :key="key+'_'+sub_key" :class="odd_even_border(key, sub_key)" >                
                                            
                                            <td>
                                                <template v-if="sub_key===0">
                                                    <label class="table-check">
                                                        <input type="checkbox"  v-model="select_export" :value="item.drug_id">
                                                        <span class="checkbox-table"></span>
                                                    </label>
                                                </template>
                                            </td>
                                            <td>
                                                <template v-if="sub_key===0">
                                                    <router-link :to="'/detail/'+item.drug_id+'/'+item.agency_model.toLowerCase()" target="_blank" rel="noopener">
                                                        <div class="icon-fiche"></div>
                                                    </router-link>                                                     
                                                    <div v-if="isAdmin">
                                                        <a :href="modifyPage('ceesp', item.drug_id)" target="_blank" rel="noopener"> 
                                                            <em class="icon-edit"></em>
                                                        </a>
                                                    </div>
                                                </template>
                                            </td>
                                            <template v-for="(column) in orderColumn" :key="column.code" >

                                                <td v-if="column.Status && column.code == 'ceesp_001'" :code="column.code">
                                                    <template v-if="sub_key===0"><span v-html="CeespDate(item)"></span></template>                        
                                                </td>
                                                <td v-else-if="column.Status && column.code == 'ceesp_002'" :code="column.code">                                                           
                                                    <template v-if="sub_key===0">{{check_empty(item.name)}}</template>
                                                </td>
                                                <td v-else-if="column.Status && column.code == 'ceesp_003'" :code="column.code"> 
                                                    <template v-if="sub_key===0">{{check_empty(item.dci)}}</template>
                                                </td>           
                                                <td v-else-if="column.Status && column.code == 'ceesp_004'" :code="column.code">                                                    
                                                    <span v-if="sub_key===0" v-html="check_empty(array(item.indexation))"></span>
                                                </td>   
                                                <td v-else-if="column.Status && column.code == 'ceesp_005'" :code="column.code">
                                                   <template v-if="sub_key===0"> {{ check_empty_with_locale(item.ceesp.impact_significatif,'yes_no_')}}</template>
                                                </td>
                                                <td v-else-if="column.Status && column.code == 'ceesp_006'" :code="column.code">
                                                    <template v-if="sub_key===0">{{ check_empty_with_locale(item.ceesp.conclusion,"conclusion_")}}</template>
                                                </td>
                                                <td v-else-if="column.Status && column.code == 'ceesp_007'" :code="column.code" >                                                     
                                                    <span v-html="check_empty(array_ceesp(item.ceesp.ceesp_titleappreserve, 'appreciation', sub_key))"></span>
                                                </td> 
                                                <td v-else-if="column.Status && column.code == 'ceesp_008'" :code="column.code" >                                                    
                                                    <Readmore v-if="$i18n.locale !== 'fr'" :longText="array_ceesp(item.ceesp.ceesp_titleappreserve, 'reverseen', sub_key)" />
                                                    <Readmore v-else :longText="array_ceesp(item.ceesp.ceesp_titleappreserve, 'reversefr', sub_key)" />
                                                </td>          
                                                <td v-else-if="column.Status && column.code == 'ceesp_009'" :code="column.code">
                                                    <template v-if="sub_key===0">{{ item.ceesp.additional_data == 0 ? "-" : check_empty_with_locale(item.ceesp.additional_data)}}</template> 
                                                </td>  
                                                 <td v-else-if="column.Status && column.code == 'ceesp_010'" :code="column.code">
                                                    <template v-if="sub_key===0">{{ item.ceesp.budget_impact == 0 ? "-" : check_empty_with_locale(item.ceesp.budget_impact)}}</template>
                                                </td> 
                                                 <td v-else-if="column.Status && column.code == 'ceesp_011'" :code="column.code">                                                                                                           
                                                    <span v-if="$i18n.locale !== 'fr'" v-html="array_ceesp2(item.ceesp.evaluation_economic[sub_key], 'evaluation_economic_economic_analysis', 'comparator_en')"></span>
                                                    <span v-else v-html="array_ceesp2(item.ceesp.evaluation_economic[sub_key], 'evaluation_economic_economic_analysis', 'comparator')"></span>
                                                </td> 
                                                 <td v-else-if="column.Status && column.code == 'ceesp_012'" :code="column.code">                                                     
                                                    <span v-html="statusEvaluationEconomic(item.ceesp.evaluation_economic[sub_key], 'evaluation_economic_economic_analysis')"></span>
                                                </td>   
                                                <td v-else-if="column.Status && column.code == 'ceesp_013'" :code="column.code">
                                                    <template v-if="sub_key===0">{{ check_empty_with_locale(item.ceesp.iab_requested, "yes_no_") }}</template>
                                                </td>  
                                                <td v-else-if="column.Status && column.code == 'ceesp_014'" :code="column.code">
                                                     <template v-if="sub_key===0">{{ check_empty_with_locale(item.ceesp.incidence_healtcare, "yes_no_")}}</template>
                                                </td> 
                                                <td v-else-if="column.Status && column.code == 'ceesp_015'" :code="column.code">
                                                    <template v-if="sub_key===0">{{check_empty_with_locale(item.ceesp.turnover_20, "yes_no_")}}</template>
                                                </td>        
                                                <td v-else-if="column.Status && column.code == 'ceesp_016'" :code="column.code">
                                                    <span v-if="sub_key === 0" v-html="check_empty(DDMMMYY(item.ceesp.date))"></span>
                                                </td>
                                                <td v-else-if="column.Status && column.code == 'ceesp_017'" :code="column.code">
                                                    <span v-if="sub_key === 0" v-html="check_empty(DDMMMYY(item.ceesp.date_avis_guidance))"></span>
                                                </td>       
                                                <td v-else-if="column.Status && column.code == 'ceesp_018'" :code="column.code"> 
                                                   <span v-html="ema_id(item.links)" v-if="sub_key===0"></span>
                                                </td>    
                                                <td v-else-if="column.Status && column.code == 'ceesp_019'" :code="column.code"> 
                                                    <span v-html="trs_id(item.links)" v-if="sub_key===0"></span>
                                                </td>   
                                                <td v-else-if="column.Status && column.code == 'ceesp_020'" :code="column.code">
                                                    <span v-if="sub_key === 0" v-html="check_empty(item.ceesp.eco_effi)"></span>
                                                </td>                                 
                                                
                                            </template>

                                        </tr>
                                        </template>
                                    </tbody>

                                </table>
                            </div>
                            <!---- Area Table Result End----->
                             
                            
                        </div>
                    </div> 

                    <div v-else class="body-w100" >
                        <div class="box-detail">
                            <div class="wrapper-box-hta">
                                <h1 class="fa-pull-left mr-4 mt-1">{{$t('No Data')}}</h1>
                                <div class="clearfix"></div>
                            </div>
                        </div>
                    </div>
                    </div>

                    <TablePrix v-if="$route.query.tab == 'prix'" main_page="Ceesp" />   

                    <TableEssaisClinique v-if="$route.query.tab == 'essais_clinique'" main_page="Ceesp" />
 
                </div>
            </div>
        </div>


        <Loader :isLoad="isLoad" />
        <Footer />
    </div>
    
</template>
<script>
import Header from '@/components/Layout/Header.vue'; 
import Footer from '@/components/Layout/Footer.vue'; 
import IconTopTable from '@/components/elements/icon_top_table.vue';
import ColumnColGroup from '@/components/elements/column_colgroup.vue';
import ColumnThead from '@/components/elements/column_thead.vue'; 
import ModalConfigColumn from '@/components/elements/modal_config_column.vue';
import ModalSaveKeyword from '@/components/elements/modal_save_keyword.vue';
import Readmore from'@/components/elements/readmore.vue';
import FilterCeesp from '@/components/advanced_result/filter_ceesp.vue';
import TablePrix from '@/components/advanced_result/table_prix.vue'
import TableEssaisClinique from '@/components/advanced_result/table_essais_clinique.vue';
import { currentDate, isAdmin, isUserFrench, eventHandlerResize, trs_id, array, ema_id, DDMMMYY, 
        check_empty, modifyPage, check_empty_with_locale, odd_even_border } from '@/utils';
import Loader from "@/components/Layout/Loader";

export default {
    name:"advanced_result_ceesp" ,
    components: {        
        Header,
        Footer,   
        IconTopTable,
        ColumnColGroup,
        ColumnThead,
        ModalConfigColumn,
        FilterCeesp,
        ModalSaveKeyword,
        TablePrix,
        TableEssaisClinique,
        Readmore,
        Loader
    },
     data() {
        return {
            page : "ceesp",            
            isFilter : false            
        }
    },
    mounted() {
        //search
        this.request_data({ mounted : true, order_by : 'decision_date_num', sort : 'desc' })
        document.body.classList.add('body-bg-gray')  
        if(this.$route.query.zoom){
            this.eventHandlerResize()
        } 
    },
    methods: {     
        request_data(param = {}){
            let paramObj = {...this.$route.params}
            paramObj['base64Search'] = paramObj['base64Search'].replace('/\-/g', '+').replace('/_/g', '/') // eslint-disable-line
            if(!param['mount'] && this.sort_column && Object.keys(this.data).includes(this.sort_column['order_by'])){
                paramObj['order_by'] = this.sort_column['order_by']
            }
            else if(param['order_by']){
                paramObj['order_by'] = param['order_by']
            }
            else{
                paramObj['order_by'] = 'decision_date_num'
            }
            paramObj['sort'] = !param['mounted'] && this.sort_column && this.sort_column['sort'] ? this.sort_column['sort'] : param['sort']
            if(param.page){
                paramObj['page'] = param.page
            }
            if(this.select_export_obj.isSelect){
                paramObj['select_export'] = this.select_export_obj.listID
            }
            if(this.data_filter !== ""){
                paramObj['filter'] = this.data_filter
            }
            if(this.$route.query.keyword_id){
                paramObj['keyword_id'] = this.$route.query.keyword_id
            }
            if(param.export){
                delete param.export;
                this.$store.dispatch(`advanced_result/result_ceesp_excel`, [paramObj, currentDate()+"_advanced_ceesp" ] ) 
            }else{
                this.$store.dispatch(`advanced_result/result_ceesp`, paramObj ) 
            } 

            if(!param.mounted){
                this.selectTab('expert')
            }
        },    
        selectTab(tab){
            let query = Object.assign({}, this.$route.query);
            if(query.tab !== tab){
                query.tab = tab            
                console.log(this.$route.query)
                this.$router.push({ query });  
            }                       
        }, 
        selectExport(){
            this.$store.dispatch("advanced_result/select_export", { key : "isSelect" , value : true})                 
            this.request_data() 
        },
        clearExport(){
            this.$store.dispatch("advanced_result/select_export", "clear")                      
            this.request_data()
        },    
        max_row(param){
            const temp = []
            temp.push(1)              
            if(param.ceesp.evaluation_economic){
                temp.push(param.ceesp.evaluation_economic.length)
            }               
            if(param.ceesp.ceesp_titleappreserve){
                temp.push(param.ceesp.ceesp_titleappreserve.length)
            }
            return Math.max(...temp)
        },
        handleClickBoxSearch(){
            this.$store.dispatch("header/setOpenBoxSearch", !this.openBoxSearch)
        },
        handleClickShowFilter(){
            this.$store.dispatch("advanced_result/set_show_filter", true)
        }, 
        eventHandlerResize,
        modifyPage,
        CeespDate(value){
            let html = ''
            if (value.ceesp.date) {
                html = html + this.DDMMMYY(value.ceesp.date)
            } 
            if (value.ceesp.demande_retiree) {
                html = html + '<br/>'+ this.$t("Demande retirée")                
            }else if (value.ceesp.date_avis_guidance){
                html = html + '<br/>'+ this.$t("avis_ceesp")  
            }else {
                html = html + '<br/>'+ this.$t("decision_has")  
            }
            return html ? html : "-"
        },
        statusEvaluationEconomic(param, foreign){
            let html = '', val2 = '', val_icer_type = '', val_icur_gbp = '', val_icer_gbp = '', val_icur_range_gbp = '', val_icer_range_gbp = ''
            let word = this.$t("per QALY gained")
            if (param) { 
                for(const key in param){
                    val2 = param[foreign]
                    if(val2 && key){
                       val2 = val2[0]
                    }
                    if(val2){
                        if(val2.icer_type){
                            val_icer_type = val2.icer_type
                        }else if(val2.icer_type_en){
                            val_icer_type = val2.icer_type_en
                        }
                        if(val2.icur_gbp){ 
                            val_icur_gbp = val2.icur_gbp 
                        }else if(val2.icur_gbp_en){  
                            val_icur_gbp = val2.icur_gbp_en
                        }
                        if(val2.icer_gbp){
                            val_icer_gbp = val2.icer_gbp
                        }else if(val2.icer_gbp_en){
                            val_icer_gbp = val2.icer_gbp_en
                        }
                        if(val2.icur_range_gbp){
                            val_icur_range_gbp = val2.icur_range_gbp
                        }else if(val2.icur_range_gbp_en){
                            val_icur_range_gbp = val2.icur_range_gbp_en
                        }
                        if(val2.icer_range_gbp){
                            val_icer_range_gbp = val2.icer_range_gbp
                        }else if(val2.icer_range_gbp_en){
                            val_icer_range_gbp = val2.icer_range_gbp_en
                        }
                    }
                }
                if(param.length > 1){
                  if (param.type_of_economic_analysis && param.type_of_economic_analysis == 'cua') {
                      if(val_icur_gbp) { html +=  val_icur_gbp + " " + word }   
                  } else if (param.type_of_economic_analysis && param.type_of_economic_analysis == 'cea') { 
                      if(val_icer_gbp) {html += val_icer_gbp + " " + val_icer_type }
                  } 
                }else{
                  if (param.type_of_economic_analysis && param.type_of_economic_analysis == 'cua') {
                      if(val_icur_gbp) { html +=  val_icur_gbp + " " + word }   
                      if(val_icur_range_gbp) { html +=  val_icur_range_gbp + " " + word }   
                  } else if (param.type_of_economic_analysis && param.type_of_economic_analysis == 'cea') { 
                      if(val_icer_gbp) {html += val_icer_gbp + " " + val_icer_type }
                      if(val_icer_range_gbp) {html += val_icer_range_gbp + " " + val_icer_type }
                  } 
                }
                
            }
            html = this.check_empty(html)
            return html
        },
        array_ceesp : function(param, foreign , index ){              
            let val = ''
            
            if (param[index]){ 
                param = param[index]
                for(const key in param){                 
                    if(key === foreign) {               
                        val = param[key]  
                    }
                }
            }
            if (foreign == 'appreciation'){
                 val ? val = this.$t("appreciation."+val) : ""
            }
            return val 
        },
        array_ceesp2 : function(param, foreign, index){ 
            let val = ''
            let val1 = ''
            let val2 = ''
            for(const key in param){                
                if(key === foreign) {
                    val1 = param[foreign]                  
                }
                for(const key in val1){                    
                        val2 = val1[key]  
                        if(val2[index]){ 
                            val = val2[index] 
                            break;
                        } 
                   
                }
            }
            val = this.check_empty(val)
            return val
        },
        trs_id,
        ema_id,
        DDMMMYY,
        check_empty,
        check_empty_with_locale,
        array,
        odd_even_border
     },     
    computed: { 
        isAdmin,
        isUserFrench,
        user(){
            return this.$store.getters['auth/user']
        },       
        data(){
            return this.$store.getters['advanced_result/data']
        },
        orderColumn(){
            return this.$store.getters['advanced_result/column']
        },
        default_column(){
            return this.$store.getters['advanced_result/default_column'];
        },
        type(){
            return this.$store.getters['advanced_result/type'] 
        },
        reset_column(){
            return this.$store.getters['advanced_result/reset_column']                       
        }, 
        openBoxSearch () {  
            return this.$store.getters['header/openBoxSearch']
        },                 
        count() {
            return this.$store.getters['advanced_result/count']
        },
        count_filter() {
            return this.$store.getters['advanced_result/count_filter']
        },
        isLoad() {  
            if(this.$store.getters['advanced_result/load'] || this.$store.getters['advanced_result/prix_load'] || this.$store.getters['essais_clinique/load']){
                return true
            }
            return false
        },
        select_export: {
            set(val){ this.$store.dispatch("advanced_result/select_export", {key: 'listID', value: val}) },
            get(){ 
                if(this.$store.getters['advanced_result/select_export'].checkAll){
                    return true
                }else{
                    return this.$store.getters['advanced_result/select_export'] ? this.$store.getters['advanced_result/select_export'].listID : null
                }
            }
        },
        select_export_obj(){
            return this.$store.getters['advanced_result/select_export']
        },
        data_filter(){
            return this.$store.getters['advanced_result/data_filter']
        },
        sort_column(){
            return this.$store.getters['advanced_result/sort_column']
        }
    },
    created() {
        if(this.$route.query.zoom){
            window.addEventListener("resize", this.eventHandlerResize);
        }
    },
    unmounted () {
        document.body.classList.remove('body-bg-gray'); 
        this.$store.dispatch("advanced_result/clearState") 
        this.$store.dispatch("essais_clinique/clearState")
        if(this.$route.query.zoom){
            window.removeEventListener("resize", this.eventHandlerResize);
        }
    }
}
</script>

<style scoped>
.main-body{
    min-height: 654px;
} 
/* CSS for fixed height of table for show in one page */
@media only screen and (max-width: 862px){
    .content-table-results{
        height: auto;
    }
}
@media only screen and (min-width: 863px){
    .content-table-results{
        max-height: 60vh;
    }
}
@media only screen and (min-height: 863px){
    .content-table-results{
        max-height: 65vh;
    }
}
@media only screen and (min-height: 1320px){
    .content-table-results{
        max-height: 75vh;
    }
}
</style>